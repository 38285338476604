import { uniq } from 'lodash';
import {
  PAYMENT_MODE_TYPE_KLARNA,
  PAYMENT_TYPE_CREDIT_CARD,
  PAYMENT_MODE_TYPE_PAYPAL,
  CARD_ICON_MAPPER,
} from './checkoutPaymentConstants';

export function getCardBrands(supportedCarts) {
  const brandArray = supportedCarts
    .filter(paymentMode => paymentMode.paymentMethod === PAYMENT_TYPE_CREDIT_CARD)
    .map(card => card.cardBrands)
    .flat();

  return uniq(brandArray);
}

export function getCardIcons(brands) {
  return brands.map(brand => CARD_ICON_MAPPER[brand]);
}

export function isKlarnaPaymentEnabledForAddress(supportedPaymentModes, selectedDeliveryAddress) {
  return (
    supportedPaymentModes.find(paymentMode => paymentMode.paymentMethod === PAYMENT_MODE_TYPE_KLARNA) &&
    window.inlineCheckout.klarnaEnabledCountries.split(', ').includes(selectedDeliveryAddress.country)
  );
}

export function isPaypalPaymentEnabled(paymentMethods) {
  return paymentMethods.some(method => method.paymentMethod === PAYMENT_MODE_TYPE_PAYPAL);
}

export function getCardIconsFromPaymentMethods(paymentMethods) {
  const cardBrands = getCardBrands(paymentMethods);
  return getCardIcons(cardBrands);
}
