export const breakpoints = {
  'landscape-orientation': '(orientation: landscape)',
  'large-only': 'only screen and (min-width:64.0625rem) and (max-width:80rem)',
  'portrait-orientation': '(orientation: portrait)',
  'small-only': 'only screen and (max-width: 40rem)',
  'xlarge-only': 'only screen and (min-width:80.0625rem) and (max-width:120rem)',
  large: 'only screen and (min-width: 64.0625rem)',
  medium: 'only screen and (min-width:40.0625rem)',
  'medium-down': 'only screen and (max-width:64rem)',
  'medium-only': 'only screen and (min-width:40.0625rem) and (max-width:64rem)',
  'medium-to-large': 'only screen and (min-width:40.0625rem) and (max-width: 1200px)',
  topbar: 'only screen and (min-width:64.0625rem)',
  xlarge: 'only screen and (min-width:80.0625rem)',
  xxlarge: 'only screen and (min-width:120.0625rem)',
};

const mediaQueries = {
  is_landscape: () => window.matchMedia(breakpoints['landscape-orientation']).matches,
  is_large_only: () => window.matchMedia(breakpoints['large-only']).matches,
  is_large_up: () => window.matchMedia(breakpoints.large).matches,
  is_medium_down: () => window.matchMedia(breakpoints['medium-down']).matches,
  is_medium_only: () => window.matchMedia(breakpoints['medium-only']).matches,
  is_medium_up: () => window.matchMedia(breakpoints.medium).matches,
  is_medium_to_large: () => window.matchMedia(breakpoints['medium-to-large']).matches,
  is_portrait: () => window.matchMedia(breakpoints['portrait-orientation']).matches,
  is_small_only: () => window.matchMedia(breakpoints['small-only']).matches,
  is_xlarge_only: () => window.matchMedia(breakpoints['xlarge-only']).matches,
  is_xlarge_up: () => window.matchMedia(breakpoints.xlarge).matches,
  is_xxlarge_up: () => window.matchMedia(breakpoints.xxlarge).matches,
};

const EM_NUMBER_OF_PIXEL = parseFloat(getComputedStyle(document.querySelector('html')).fontSize);

export const getMinSizeByBreakpoint = breakpointName => {
  const mediaQuery = breakpoints[breakpointName];
  const re = /.*min-width:(.*)rem.*/g;

  let result = 0;

  if (re.test(mediaQuery)) {
    result = mediaQuery.replace(re, '$1') * EM_NUMBER_OF_PIXEL;
  }

  return result;
};

export default mediaQueries;
