import Siema from 'siema';
import { filter } from 'lodash';
import mediaQueries, { getMinSizeByBreakpoint } from 'app/utils/mediaQueries';
import { mountAnalytics } from 'app/analytics/productCarouselAnalytics';

export const SMALL_BREAKPOINT = 'small-only';
export const MEDIUM_BREAKPOINT = 'medium';
export const LARGE_BREAKPOINT = 'large';

export const getActualScreenSize = () => {
  if (mediaQueries.is_large_up()) {
    return LARGE_BREAKPOINT;
  }

  if (mediaQueries.is_medium_only()) {
    return MEDIUM_BREAKPOINT;
  }

  return SMALL_BREAKPOINT;
};

export const updateArrows = (mySiema, slidesPerPage) => {
  const firstSlideIndex = 0;
  const currentSlide = mySiema.currentSlide || 0;
  mySiema.prevArrow.disabled = firstSlideIndex === currentSlide;
  mySiema.nextArrow.disabled = currentSlide + slidesPerPage === mySiema.innerElements.length;
};

const DEFAULT_CAROUSEL_SELECTOR = '.product-carousel-standard';
const slidesPerSmallPage = 1;
const slidesPerMediumPage = 3;
const slidesPerLargeUpPage = 4;

const slidesPerPageSizeDefault = {
  [SMALL_BREAKPOINT]: slidesPerSmallPage,
  [MEDIUM_BREAKPOINT]: slidesPerMediumPage,
  [LARGE_BREAKPOINT]: slidesPerLargeUpPage,
};

const replaceImgSrcWithAltImg = event => {
  const pictureSources = event.currentTarget.children[0].children;

  for (let i = 0; i < pictureSources.length; i++) {
    const source = pictureSources[i];
    const mainImageSource = source.getAttribute('src') || source.getAttribute('srcset');
    const mainImageUrl = new URL(`https:${mainImageSource}`);
    const altImage = source.getAttribute('dataaltimage');

    if (!altImage) {
      break;
    }

    const mainImageRegExp = new RegExp(
      `//${mainImageUrl.hostname}${mainImageUrl.pathname}`,
      'g'
    );

    if (source.getAttribute('src')) {
      source.setAttribute('src', source.getAttribute('src').replace(mainImageRegExp, altImage));
    }

    source.setAttribute('srcset', source.getAttribute('srcset').replace(mainImageRegExp, altImage));
    source.setAttribute(
      'dataaltimage',
      `//${mainImageUrl.hostname}${mainImageUrl.pathname}`
    );
  }
};

export function addArrowsDefault(siema) {
  siema.prevArrow = document.createElement('button');
  siema.nextArrow = document.createElement('button');
  siema.prevArrow.setAttribute('aria-label', 'previous');
  siema.prevArrow.classList.add('product-carousel__prev');
  siema.nextArrow.setAttribute('aria-label', 'next');
  siema.nextArrow.classList.add('product-carousel__next');
  siema.selector.appendChild(siema.prevArrow);
  siema.selector.appendChild(siema.nextArrow);
  siema.prevArrow.addEventListener('click', () => siema.prev());
  siema.nextArrow.addEventListener('click', () => siema.next());
}

function configurePaginationDefault(siema) {
  const ul = document.createElement('ul');
  const nrOfSlidesPerPage = slidesPerPageSizeDefault[getActualScreenSize()];
  const nrOfDots = siema.innerElements.length - nrOfSlidesPerPage + 1;
  const slickDots = siema.selector.querySelector('.product-carousel__dots');
  if (slickDots) {
    slickDots.parentNode.removeChild(slickDots);
  }
  ul.classList.add('product-carousel__dots');
  siema.selector.appendChild(ul);
  if (nrOfDots === 1) {
    return;
  }
  for (let i = 0; i < nrOfDots; i++) {
    const li = document.createElement('li');
    const button = document.createElement('button');
    button.setAttribute('aria-label', 'Carousel pagination button');
    button.setAttribute('type', 'button');
    button.addEventListener('click', () => siema.goTo(i));
    li.appendChild(button);
    ul.appendChild(li);
  }
  if (ul.children[siema.currentSlide]) {
    ul.children[siema.currentSlide].classList.add('active');
  }
}

const handleChangeDefault = (mySiema, carousel) => {
  updateArrows(mySiema, slidesPerPageSizeDefault[getActualScreenSize()]);

  const carouselDots = carousel.querySelector('.product-carousel__dots');
  if (!carouselDots || !carouselDots.children) {
    return;
  }

  const currentSlide = mySiema.currentSlide || 0;
  [].forEach.call(carouselDots.children, (element, index) => {
    if (index === currentSlide) {
      element.classList.add('active');
      return;
    }
    element.classList.remove('active');
  });
};

export function initProductCarousels({
  selector = DEFAULT_CAROUSEL_SELECTOR,
  currentSlides = [],
  reinitializeAll = false,
  slidesPerPage = slidesPerPageSizeDefault[getActualScreenSize()],
  perPage = {
    [getMinSizeByBreakpoint(SMALL_BREAKPOINT)]: slidesPerSmallPage,
    [getMinSizeByBreakpoint(MEDIUM_BREAKPOINT)]: slidesPerMediumPage,
    [getMinSizeByBreakpoint(LARGE_BREAKPOINT)]: slidesPerLargeUpPage,
  },
  addPagination = configurePaginationDefault,
  onChange = handleChangeDefault,
  addArrows = addArrowsDefault,
} = {}) {
  let carousels = document.querySelectorAll(selector);
  const carouselClones = [];
  const siemaCarousels = [];

  // Note:
  // Every independent carousel component is calling this productCarousel.js function
  // the problem is that this file initializes all carousels in the page, so
  // every carousel initializes all carousels multiple times, that generates different errors
  // see: HYB-1986, this needs to be refactorized so every carousel acts independently,
  // maybe by passing a carousel html element to intialize.
  // by now this does the trick, but it feels wrong:
  if (!reinitializeAll) {
    // on a simple initialize remove carousels already initialized HYB-1986
    carousels = filter(carousels, carousel => !carousel.classList.contains('initialized'));
  }

  return (() => {
    for (let idx = 0; idx < carousels.length; idx++) {
      let carousel = carousels[idx];
      const firstSlideIndex = 0;
      const isDraggable = carousel.children.length > slidesPerPage;

      if (!carouselClones[idx]) {
        carouselClones[idx] = carousel.cloneNode(true);
      } else {
        const carouselParent = carousel.parentNode;
        carouselParent.removeChild(carousel);
        carousel = carouselClones[idx].cloneNode(true);
        carouselParent.appendChild(carousel);
      }

      const disableSingleArrow = siema => {
        const hidePrevArrow = siema.selector.lastChild.firstChild?.classList?.contains('active') || false;
        const hideNextArrow = siema.selector.lastChild.lastChild?.classList?.contains('active') || false;
        if (hidePrevArrow) {
          siema.prevArrow.disabled = true;
        }
        if (hideNextArrow) {
          siema.nextArrow.disabled = true;
        }
      };

      const disableArrows = siema => {
        if (siema.nextArrow && siema.prevArrow) {
          siema.nextArrow.disabled = true;
          siema.prevArrow.disabled = true;
        }
      };

      const initArrowsAndPagination = siema => {
        addArrows(siema);
        addPagination(siema);
      };

      const mySiema = new Siema({
        selector: carousel,
        duration: 300,
        easing: 'linear',
        draggable: isDraggable,
        loop: false,
        perPage,
        onChange: () => onChange(mySiema, carousel),
        onInit() {
          initArrowsAndPagination(this);
        },
      });

      carousel.classList.add('initialized');
      const currentSlide = currentSlides[idx] || 0;
      mySiema.goTo(currentSlide);
      if (mySiema.prevArrow && mySiema.nextArrow) {
        mySiema.prevArrow.disabled = firstSlideIndex === currentSlide;
        mySiema.nextArrow.disabled =
          slidesPerPage >= mySiema.innerElements.length ||
          currentSlide === mySiema.innerElements.length - slidesPerPage;
      }

      window.addEventListener('resize', () => {
        initArrowsAndPagination(mySiema);
        const slidesCount = mySiema.innerElements.length;
        const screenSize = getActualScreenSize();
        if (slidesCount >= slidesPerLargeUpPage) {
          return screenSize === LARGE_BREAKPOINT && slidesCount === slidesPerLargeUpPage
            ? disableArrows(mySiema)
            : disableSingleArrow(mySiema);
        }
        if (slidesCount > slidesPerSmallPage && slidesCount <= slidesPerMediumPage) {
          return screenSize !== SMALL_BREAKPOINT ? disableArrows(mySiema) : disableSingleArrow(mySiema);
        }
        return disableArrows(mySiema);
      });

      siemaCarousels.push(mySiema);

      [].forEach.call(document.querySelectorAll(`${selector} .product-carousel__image-container`), element => {
        element.addEventListener('mouseover', replaceImgSrcWithAltImg);
        element.addEventListener('mouseout', replaceImgSrcWithAltImg);
      });
    }
    mountAnalytics();
  })();
}
