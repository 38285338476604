export const DETAILS_LOADED = 'DETAILS_LOADED';
export const ADD_TO_COLLECTION = 'ADD_TO_COLLECTION';
export const SIZE_SELECTED = 'SIZE_SELECTED';
export const SIZE_DESELECTED = 'SIZE_DESELECTED';
export const UPDATE_IN_WISHLIST_PRODUCTS = 'UPDATE_IN_WISHLIST_PRODUCTS';
export const PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND';
export const FETCH_USER_ENCODED_ID = 'FETCH_USER_ENCODED_ID';

// Product types
export const STICKER = 'Sticker';
export const SHOE = 'Shoe';
export const LEISUREWEAR = 'Leisurewear';
export const SOCK = 'Sock';
export const CHARM = 'Charm';
